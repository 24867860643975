.sidebar {
  position: fixed;
  top: 0;
  right: -100%;
  bottom: 0;
  width: 50rem;
  max-width: 100%;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: all 0.5s ease;
  z-index: 1000;
}

.sidebar.open {
  right: 0;
}

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s ease;
}

.backdrop.open {
  opacity: 1;
  pointer-events: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.messages {
  height: 100%;
  overflow-y: scroll;
  padding-right: 2rem;
  padding-left: 0.5rem;
  width: 100%;
  padding-top: 1rem;
  position: absolute;
  padding-bottom: 7rem;
}

.messages::-webkit-scrollbar {
}

.messages .message {
  margin-bottom: 1rem;
  display: flex;
}

.bubble {
  padding: 0.5rem;
  border-radius: 0.5rem;
  max-width: 90%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
}

.message.me {
  justify-content: flex-end;
}

.message.you {
  justify-content: flex-start;
}

.send {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0.5rem;
  background-color: #fff;
}

.loader {
  border: 24px solid;
  border-color: rgba(255, 255, 255, 0.15) rgba(255, 255, 255, 0.25)
    rgba(255, 255, 255, 0.35) rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: animloader 1s linear infinite;
}

@keyframes animloader {
  0% {
    border-color: rgba(255, 255, 255, 0.15) rgba(255, 255, 255, 0.25)
      rgba(255, 255, 255, 0.35) rgba(255, 255, 255, 0.75);
  }
  33% {
    border-color: rgba(255, 255, 255, 0.75) rgba(255, 255, 255, 0.15)
      rgba(255, 255, 255, 0.25) rgba(255, 255, 255, 0.35);
  }
  66% {
    border-color: rgba(255, 255, 255, 0.35) rgba(255, 255, 255, 0.75)
      rgba(255, 255, 255, 0.15) rgba(255, 255, 255, 0.25);
  }
  100% {
    border-color: rgba(255, 255, 255, 0.25) rgba(255, 255, 255, 0.35)
      rgba(255, 255, 255, 0.75) rgba(255, 255, 255, 0.15);
  }
}
